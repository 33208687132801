/**
 * Settings slug
 */
export const SettingsSlugs = {
  importantDates: "important-dates",
  closingDate: "closing-date",
  paysheetLine: "paysheet-line",
  salaryConstants: "salary-constants",
  serviceSettings: "service-settings",
};

/**
 *
 */
export const NB_DAYS_PAID_LEAVE = 2.2;

/**
 *
 */
export const TAXE_ITS = "TAXE_ITS";
export const TAXE_VPS = "TAXE_VPS";

/**
 * @brief SALARY_CONSTANTS
 */
export const enum SALARY_CONSTANTS {
  CONVENTIONAL_HOURLY_BASE = "CONVENTIONAL_HOURLY_BASE",
  COMPANY_PENSION_ASSURANCE_RATE = "COMPANY_PENSION_ASSURANCE_RATE",
  PROFESSIONAL_RISK = "PROFESSIONAL_RISK",
  COMPANY_FAMILY_PRESTATION_RATE = "COMPANY_FAMILY_PRESTATION_RATE",
  COMPANY_LOCAL_INCOME_TAX = "COMPANY_LOCAL_INCOME_TAX",
  COMPANY_EXPAT_INCOME_TAX = "COMPANY_EXPAT_INCOME_TAX",
  COMPANY_CMU = "COMPANY_CMU",
  EMPLOYEE_CMU = "EMPLOYEE_CMU",
  FDFP_TFC = "FDFP_TFC",
  FDFP_TA = "FDFP_TA",
  TAXE_ITS_RICF = "TAXE_ITS_RICF",
  FAMILY_REDUCTION_TAX = "FAMILY_REDUCTION_TAX",
  EMPLOYEE_PAYMENT_ON_SALARY = "EMPLOYEE_PAYMENT_ON_SALARY",
  EMPLOYEE_PENSION_ASSURANCE_RATE = "EMPLOYEE_PENSION_ASSURANCE_RATE",
  ADVANCE_PAYMENT_OF_INCOME_TAX_ON_PROFITS_IFU = "ADVANCE_PAYMENT_OF_INCOME_TAX_ON_PROFITS_IFU",
  ADVANCE_PAYMENT_OF_INCOME_TAX_ON_PROFITS_NO_IFU = "ADVANCE_PAYMENT_OF_INCOME_TAX_ON_PROFITS_NO_IFU",
  TRAVEL_INDEMNITY_EXEMPT = "TRAVEL_INDEMNITY_EXEMPT",
}

/**
 * @brief PAYSHEET_LINES
 */
export const enum PAYSHEET_LINES {
  CATEGORY_SALARY = "CATEGORY_SALARY",
  OVER_SALARY = "OVER_SALARY",
  CNPS_PROFESSIONAL_RISKS = "CNPS_PROFESSIONAL_RISKS",
  CNPS_RETIREMENT_INSURANCE = "CNPS_RETIREMENT_INSURANCE",
  CNPS_FAMILY_BENEFITS = "CNPS_FAMILY_BENEFITS",
  CNPS_CMU = "CNPS_CMU",
  TAXE_VPS = "TAXE_VPS",
  TAXE_ITS = "TAXE_ITS",
  TAXE_IS = "TAXE_IS",
  TAXE_FDFP_TFC = "TAXE_FDFP_TFC",
  TAXE_FDFP_TA = "TAXE_FDFP_TA",
  TRAVEL_INDEMNITY_EXEMPT_NO = "TRAVEL_INDEMNITY_EXEMPT_NO",
  SALARY_ADVANCE = "SALARY_ADVANCE",
  SALARY_ACOMPTE = "SALARY_ACOMPTE",
  REIMBURSEMENT_ADVANCE_SALARY = "REIMBURSEMENT_ADVANCE_SALARY",
  DEDUCTION = "DEDUCTION",
  INDEMNITY = "INDEMNITY",
  TRAVEL_INDEMNITY_EXEMPT = "TRAVEL_INDEMNITY_EXEMPT"
}

/**
 *
 */
export const ADJUSTMENT_SALARY_ADVANCE = "ADJUSTMENT_SALARY_ADVANCE";

import React, {useState} from "react";
import {Cell, Pie, PieChart, ResponsiveContainer, Sector} from "recharts";

export default function DashboardPieChart({data, centerLegend = true}: { data: Array<any>, centerLegend?: boolean }) {

    const [activeIndex, setActiveIndex] = useState<any>(0)


    const onPieEnter = (_: any, index: any) => {
        setActiveIndex(index)
    };


    const renderActiveShape = (props: any) => {
        const {cx, cy, fill, payload, percent, innerRadius, outerRadius, startAngle, endAngle} = props;
        return (
            <g>
                <Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle}
                        endAngle={endAngle} fill={fill}/>
                <text className="font-semibold" x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                    {`${payload.name} ${(percent * 100).toFixed(0)}% `}
                </text>
            </g>
        );
    };


    return (
        <ResponsiveContainer width="100%" height="100%">
            <PieChart width={100} height={100}>
                <Pie
                    data={data}
                    cx="50%"
                    cy="50%"
                    labelLine={true}
                    outerRadius={80}
                    innerRadius={50}
                    fill="#8884d8"
                    dataKey="value"
                    activeIndex={activeIndex}
                    activeShape={!centerLegend ? undefined : renderActiveShape}
                    onMouseEnter={onPieEnter}
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color}/>
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    )
}

import React, {MouseEventHandler, useEffect} from "react";
import classes from "./Navbar.module.scss";
import logo from "@/assets/uptimise.png";
import avatar from "@/assets/avatar.png";
import DropdownMenu from "./DropdownMenu";
import {getUserNotifications, getUsers} from "@/redux/features/usersSlice";
import {useAppDispatch, useAppSelector} from "@/hooks/reduxToolkitHook";
import keycloak from "@/utils/keycloakUtils";
import {User} from "@/types/UserType";
import {getUserFullName, getUserPositionInCompany} from "@/utils/utils";
import {batch} from "react-redux";
import {fetchAbsencesTypes} from "@/redux/features/absencesSlice";
import {subscribe, unsubscribe} from "@/utils/events";
import {fetchPaysheetGenerationJob} from "@/redux/features/paysheetsSlice";
import {defineTargetMonth} from "pag_collabo/payslip/utils/utils";
import {updateTargetMonth} from "@/redux/features/targetMonthSlice";
import {JobType} from "@/types/SettingsType";
import {RootState} from "@/store";
import {fireAlert} from "@/redux/features/alertsSlice";
import {useHistory} from "react-router";
import {Link} from "react-router-dom";
import {styled} from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import {Notification} from "pag_collabo/notifications/Notification";
import {getValidNotifications} from "pag_collabo/notifications/utils";


const subMenuItemStyle = "flex items-center gap-3 py-2";

const Account = ({user}: { user: User }) => {
    return (
        <div className={subMenuItemStyle}>
            <img src={avatar} alt=""
                 className="nav-avatar rounded-lg mx-1 w-10 h-10 cursor-pointer"/>
            <span className="ml-0.5 cursor-pointer">
        <p className="text-black ">{getUserFullName(user)}</p>
        <p className="text-blue-400 text-xs">{getUserPositionInCompany(user)}</p>
      </span>
        </div>
    );
};

export const SubMenuItem = ({
                                text,
                                icon,
                                onClick,
                                className,
                                textClassName,
                            }: {
    text: string,
    icon?: string,
    className?: string,
    textClassName?: string,
    onClick?: MouseEventHandler<HTMLDivElement>
}) => {

    return (
        <div onClick={onClick} className={`flex ${className ? className : "space-x-6 mb-5 w-full"}`}>
            {icon && <i className={`${icon} ut--lg text-gray-500`}/>}
            <p className={textClassName ? textClassName : "text-black"}>{text}</p>
        </div>
    );
};

const CustomMenu = styled((props) => (
    <Menu elevation={0}
          anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
          }}
          style={{top: "30px"}}{...props} />
))(({theme}) => ({
    "& .MuiPaper-root": {
        borderRadius: 10,
        minWidth: 180,
        color:
            theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    },
    "& .MuiMenu-list": {
        padding: "0px 0",
    },
}));

const Navbar = ({boardingInProgress}: { boardingInProgress: boolean, }) => {
    const dispatch = useAppDispatch();
    const {auth, company, users, usersStatus, userInfos, userNotification} = useAppSelector((state) => state.users);
    const {paysheetsGenerationJob} = useAppSelector((state: RootState) => state.paysheets);
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const validNotifications = getValidNotifications(userNotification, users);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    useEffect(() => {
        dispatch(getUserNotifications());
    }, [dispatch]);

    // useEffect(() => {
    //     // Fetch authenticated user infos from keycloak
    //     getUserKcInfos().catch(console.error);
    //     subscribe("userKcInfosReady", () => {
    //         dispatch(setUserKcInfos());
    //     });
    // }, [dispatch]);
    useEffect(() => {
        subscribe("userKcInfosReady", () => {
            if (usersStatus === "idle") {
                batch(() => {
                    dispatch(fetchPaysheetGenerationJob());
                    dispatch(getUsers());
                    dispatch(fetchAbsencesTypes());
                });
            } else if (usersStatus === "failed") {
                dispatch(fireAlert({
                        type: "error",
                        message: "Une erreur s'est produite",
                    },
                ));
            }
        });
        return () => {
            unsubscribe("userKcInfosReady", () => {
                return;
            });
        };
    }, [usersStatus, dispatch]);

    useEffect(() => {
        let jobDate = undefined;
        if (paysheetsGenerationJob) {
            jobDate = (paysheetsGenerationJob as JobType).date;
        }
        dispatch(updateTargetMonth(defineTargetMonth(jobDate)));
    }, [paysheetsGenerationJob]);

    const logout = () => {
        localStorage.clear();
        keycloak.logout();
    };

    const change = () => {
        history.push("/entreprises")
    }

    const subMenu = () => {
        if (userInfos?.attributes?.companies?.length > 1) {
            return (
                <div>
                    <SubMenuItem className={subMenuItemStyle} text="Changer d'entreprise" icon="ut-spe-work"
                                 onClick={() => change()}/>
                    <SubMenuItem className={subMenuItemStyle} text="Se déconnecter" icon="ut-logout"
                                 onClick={() => logout()}/>
                </div>


            );
        }
        return <SubMenuItem text={"Se déconnecter"} icon="ut-logout" onClick={() => logout()}/>
    }

    return (
        <nav className={`${classes.nav} bg-blue-500 items-center w-full py-3 pl-14`}>

            <Link className="cursor-pointer" to={"/"}>
                <img className="w-[150px]" src={logo} alt="logo uptimise"/>
            </Link>

            {/*{!boardingInProgress &&*/}
            {/*<div className="w-50">*/}
            {/*  <SearchBar*/}
            {/*    className={classes.searchbar}*/}
            {/*    placeholder="Effectuer une recherche ..."*/}
            {/*  />*/}
            {/*</div>*/}
            {/*}*/}

            {!boardingInProgress && (
                <div className="flex">
                    {
                        <>
                            <div className="">
                                <CustomMenu open={open} onClose={handleClose} anchorEl={anchorEl}>
                                    <Notification/>
                                </CustomMenu>
                                {/*<Popover*/}
                                {/*    id={id}*/}
                                {/*    open={open}*/}
                                {/*    anchorEl={anchorEl}*/}
                                {/*    onClose={handleClose}*/}
                                {/*    anchorOrigin={{*/}
                                {/*        vertical: 'bottom',*/}
                                {/*        horizontal: 'left',*/}
                                {/*    }}*/}
                                {/*    style={{ top: '30px'}}*/}
                                {/*>*/}
                                {/*    <Notification/>*/}
                                {/*</Popover>*/}
                            </div>
                            <div className="relative cursor-pointer mr-3 mt-4"
                                 onClick={handleClick}
                            >
                                <i className="ut-notifications ut--lg text-white"/>
                                {
                                    validNotifications.length ?
                                    <span className={`${classes.notif} absolute bg-danger font-semibold`}>
                                        {validNotifications.filter(notif => !notif.is_read).length}
                                    </span> : null
                                }
                            </div>
                        </>
                    }
                    <DropdownMenu
                        buttonChild={
                            <div className="flex items-center pr-7">
                                {/*<div className="relative cursor-pointer mr-3">*/}
                                {/*  <i className="ut-notifications ut--lg text-white" />*/}
                                {/*  <span*/}
                                {/*    className={`${classes.notif} absolute bg-danger font-semibold`}>4</span>*/}
                                {/*</div>*/}
                                <div className="flex items-center">
                                    {/*<img src={avatar} alt=""*/}
                                    {/*<img src={LogoErugis} alt=""*/}
                                    {/*     className="nav-avatar rounded-lg mx-1 w-10 h-10 cursor-pointer" />*/}
                                    <span className="ml-0.5 cursor-pointer flex flex-col items-start">
                    <p
                        className="mr-auto text-white font-semibold capitalize truncate">{company?.last_name}</p>
                    <p
                        className="text-gray-300 text-xs capitalize font-normal">{users.length} Collaborateurs</p>
                  </span>
                                    <i className={`ut-arrow-down ut--sm text-white ml-4`}/>
                                </div>
                            </div>
                        }

                        menuItemChildren={[
                            ...(!auth?.is_primary ? [
                                <Account user={auth as User}/>,
                                // <SubMenuItem text="Changer de compte" icon="ut-swap-vert rotate-90"
                                //              onClick={() => console.log("compte")} />,
                            ] : []),

                            subMenu()

                        ]}
                        menu
                    />
                </div>
            )}
        </nav>
    );
};


export default Navbar;

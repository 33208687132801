import {
  ADDRESS_INFOS_ROUTE,
  BALANCE_ROUTE,
  BASIC_REMUNERATION_ROUTE,
  BASIC_SALARY_ROUTE,
  CONTRACT_DURATION_ROUTE,
  CONTRACT_END_INDEMNITY_ROUTE,
  CONTRACT_END_REASON_ROUTE,
  CONTRACT_END_RESUME,
  CONTRACT_TYPE_ROUTE,
  PERSONAL_INFOS_ROUTE,
  PUBLIC_INFOS_ROUTE,
  WORK_CLASSIFICATION_ROUTE,
  WORK_TIME_ROUTE,
} from "@/constants/ajoutCollaboRoutes";
import {UserRolesType} from "@/types/UserType";

export const ENVIRONMENT = `${import.meta.env.VITE_ENVIRONMENT}`;
export const SENTRY_DSN = `${import.meta.env.VITE_SENTRY_DSN}`;

// slug
export const SettingsPaysheetLines = "paysheet-line";
export const SettingsSalaryConstants = "salary-constants";
export const SettingsClosingDate = "closing-date";
export const SettingsCompanyInfos = "company-infos";
export const AutomaticAwardsRules = "automatic-awards-rules";
export const CompanySector = "company-sector";
export const Holidays = "holidays";

// unleash conf
export const flag_config = {
  url: `${import.meta.env.VITE_UNLEASH_CLIENT_BASE_URL}`,
  appName: `${import.meta.env.VITE_UNLEASH_APP_NAME}`,
  clientKey: `${import.meta.env.VITE_UNLEASH_CLIENT_KEY}`,
  refreshInterval: `${import.meta.env.VITE_UNLEASH_REFRESH_INTERVAL}`,
  environment: ENVIRONMENT,
};


export const REGISTRATION_STEPS: Array<{
  key: number;
  seniority: boolean;
  label: string;
  steps: Array<{ path: string }>;
}> = [
  {
    key: 1,
    seniority: false,
    label: "Informations personnelles",
    steps: [
      { path: PERSONAL_INFOS_ROUTE },
      { path: ADDRESS_INFOS_ROUTE },
      { path: PUBLIC_INFOS_ROUTE },
    ],
  },
  {
    key: 2,
    seniority: false,
    label: "Informations contractuelles",
    steps: [
      { path: CONTRACT_TYPE_ROUTE },
      { path: WORK_CLASSIFICATION_ROUTE },
      { path: CONTRACT_DURATION_ROUTE },
    ],
  },
  {
    key: 3,
    seniority: false,
    label: "Temps de travail et rémunération",
    steps: [
      {path: WORK_TIME_ROUTE},
      {path: BASIC_SALARY_ROUTE},
    ],
  },
  {
    key: 4,
    seniority: true,
    label: "Reprise Historique",
    steps: [
      {path: BALANCE_ROUTE},
      {path: BASIC_REMUNERATION_ROUTE},
    ],
  },
];


export const END_CONTRACT_STEPS: Array<{
  key: number;
  seniority: boolean;
  label: string;
  steps: Array<{ path: string }>;
}> = [
  {
    key: 1,
    seniority: false,
    label: "Mettre fin au contrat",
    steps: [
      {path: CONTRACT_END_REASON_ROUTE},
      {path: CONTRACT_END_INDEMNITY_ROUTE},
      {path: CONTRACT_END_RESUME},
    ],
  },
];

export const ROLES: Array<{
  value: UserRolesType;
  label: string;
  description?: string;
  weigh: number,
  assignable: boolean,
  attributs: Array<{ title: string, description: string }>;
}> = [
  {
    value: "OWNER",
    label: "Propriétaire",
    description: "Le compte Propriétaire est le premier compte créé pour votre entreprise lorsque vous rejoignez Uptimise. Il détient les mêmes autorisations que les administrateurs de l'entreprise. Chaque entreprise ne peut avoir qu'un seul compte Propriétaire, et celui-ci ne peut pas être supprimé.",
    weigh: 5,
    assignable: false,
    attributs: [{ title: "", description: "" }],
  },
  {
    value: "ADMIN",
    label: "Administrateur",
    description: "Cet utilisateur est conçu pour ceux qui vont gérer la paie et les RH de votre entreprise. Les administrateurs ont tous les pouvoirs : ils peuvent ajouter, modifier ou supprimer des collaborateurs, des variables et d'autres administrateurs. Vous pouvez avoir un nombre illimité d'administrateurs.",
    weigh: 4,
    assignable: true,
    attributs: [{ title: "", description: "" }],
  },
  /*{
    value: "ACCOUNTANT",
    label: "Comptable",
    weigh: 3,
    assignable: true,
    attributs: [{ title: "", description: "" }],
  },
  {
    value: "MANAGER",
    label: "Manager",
    weigh: 2,
    assignable: true,
    attributs: [{ title: "", description: "" }],
  },
  {
    value: "COLLABO",
    label: "Collaborateur",
    weigh: 1,
    assignable: true,
    attributs: [{ title: "", description: "" }],
  },*/
];
import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { store } from "./store";
import { Provider } from "react-redux";

import * as Sentry from "@sentry/react";
import { BrowserTracing, Replay } from "@sentry/react";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { CircularProgress } from "@mui/material";
import keycloak from "@/utils/keycloakUtils.ts";
import { ENVIRONMENT, flag_config, SENTRY_DSN } from "@/constants/settings";
import FlagProvider from "@unleash/proxy-client-react";

if (ENVIRONMENT)
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENVIRONMENT,
    integrations: [
      new BrowserTracing(),
      new Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

ReactDOM.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={{ onLoad: "login-required" }}
    LoadingComponent={
      <div className="flex items-center justify-center h-screen">
        <CircularProgress size={70} />
      </div>}
  >
    <React.StrictMode>
      <Provider store={store}>
        <FlagProvider config={flag_config}>
          <App />
        </FlagProvider>
      </Provider>
    </React.StrictMode>
  </ReactKeycloakProvider>,
  document.getElementById("root"),
);

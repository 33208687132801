import { User } from "@/types/UserType";

export type AwardsTypes = {
  id: string;
  label: string;
  ref: string;
  value: string;
  is_automatic: boolean;
}

export type UserAwardsType = {
  id?: string;
  amount?: number | null;
  percentage?: number | null;
  frequency?: number | null;
  amount_calcul_basis: string | null;
  include_in_gross_salary?: boolean;
  other_type_label?: string | null;
  start_date: Date | string | null;
  end_date?: Date | string | null;
  user: string | null;
  award_type: string | AwardsTypes | null;
  award_details: AwardDetailType[]
  created_at?: Date | string | null;
  updated_at?: Date | string | null;
}

export type UserAwardsFormType = {
  users_ids?: any[];
  user?: string;
  amount?: number | null;
  percentage?: number | null;
  frequency: number | null;
  amount_calcul_basis?: string | null;
  include_in_gross_salary?: boolean;
  other_type_label?: string | null;
  start_date: Date | string | null;
  end_date?: Date | string | null;
  award_type: AwardsTypes | string | null;
  rewarding_method?: string
}

export type AwardsModificationFormType = {
  amount?: number | null;
  percentage?: number | null;
  frequency?: number | null;
  amount_calcul_basis?: string | null;
  other_type_label?: string | null;
  end_date?: Date | string | null;
  award_type?: AwardsTypes | string | null;
  rewarding_method?: string
}

export type UserWithAwardType = {
  user: User
  awards: UserAwardsType[];
}

export type AwardDetailType = {
  id: string;
  created_at: Date;
  updated_at: Date;
  date: string;
  amount: string;
  award: string;
}

export const DEFAULT_FREQUENCIES = [
  {
    label: "Une fois",
    value: 0,
  },
  {
    label: "Mensuelle",
    value: 1,
  },
  {
    label: "Bimensuelle",
    value: 2,
  },
  {
    label: "Trimestrielle",
    value: 3,
  },
  {
    label: "Semestrielle",
    value: 6,
  },
  {
    label: "Annuelle",
    value: 12,
  },
];

export type FetchUpdateDeleteEntryType = {
  pk: string;
  id: string
}

export type UpdateAwardType = {
  pk: string;
  id: string
  award: Partial<UserAwardsFormType>
}

/* Use percentage or fixed amount for the bonus  */
export const BONUS_CALCUL_OPTIONS = [
  { value: "percentage", text: "Pourcentage", defaultChecked: true },
  { value: "fixed", text: "Montant fixe" },
];

/* Basis of the calculation of the bonus */
export const BASE_CALCUL_BONUS = [
  {
    label: "Salaire de base sans heure supplémentaire",
    value: "BASE_SALARY",
  },
  {
    label: "Salaire brut",
    value: "GROSS_SALARY",
  },
];

// export type AutomatiqueAwardsRulesType = { "seniority_award_type_rules": { [key: string]: any } }
export type AutomatiqueAwardsRulesType = {
  seniority_award_type_rules: Array<{
    end: number | null;
    rate: { step: number; seniority_base: number; percentage_base: number; } | number;
    start: number;
  }>;
}


import React from 'react'

export interface ILegend { label: string, color: string }

export default function ChartLegend({ items }: { items?: ILegend[] }) {
  return (
    <div className='px-12 mt-8'>
      {items &&
        <>
          <div className="w-full flex justify-center gap-4">
            {items.map((item: ILegend, i: number) => (
                <div key={i} className="flex justify-center items-center gap-x-2">
                  <span className="h-2 w-2 rounded-full" style={{backgroundColor: item.color}}></span>
                  <p className='text-gray2'>{item.label}</p>
              </div>
            ))}
          </div>
        </>
        }

    </div>
  )
}

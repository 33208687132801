import React, {useState} from "react";
import "./MenuCompany.scss";
import {NavLink, useLocation, useRouteMatch} from "react-router-dom";
import MuiAccordion, {AccordionProps} from "@mui/material/Accordion";
import MuiAccordionSummary, {AccordionSummaryProps} from "@mui/material/AccordionSummary";
import {AccordionDetails} from "@mui/material";
import menus from "@/constants/appMenus";

const Accordion = (props: AccordionProps) => (
    <MuiAccordion
        disableGutters
        elevation={0}
        square
        {...props}
        children={props.children}
        sx={{
            "&:not(:last-child)": {
                borderBottom: 0,
            },
            "&:before": {
                display: "none",
            },
        }}
    />);

const AccordionSummary = (props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        {...props}
        sx={{
            flexDirection: "row-reverse",
            "& .MuiAccordionSummary-content": {
                // marginLeft: theme.spacing(1),
                margin: "0px auto",
            },
        }}
    />
);

const MenuCompany = () => {
    const location = useLocation();
    const [expanded, setExpanded] = useState(-1);

    const handleCollapse = (panel: number) => {
        setExpanded(val => val == panel ? -1 : panel);
    };

    return (
        <div className="mt-10">
            <NavLink to="/" className="">
                <div
                    className={"mx-5 px-3 flex items-center py-[10px] rounded cursor-pointer text-blue-800 " + (useRouteMatch({
                        path: ["/", "/dashboard"],
                        exact: true,
                    }) ? "bg-blue-100 hover:text-blue-800" : "hover:text-blue-500")}>
                    <i className="ut-spe-category ut--xl mr-3"/>
                    <p className="font-medium">Tableau de bord</p>
                </div>
            </NavLink>


            {menus.map((menu, i) => {
                return "subMenus" in menu ?
                    <Accordion key={i}
                               expanded={!!menu.subMenus.filter(x => location.pathname.includes(x.link)).length || expanded == i}
                               className={"mx-5 my-[10px] rounded cursor-pointer " + (!!menu.subMenus.filter(x => location.pathname.includes(x.link)).length ? "!bg-blue-100" : "")}
                               onChange={() => handleCollapse(i)}>
                        <AccordionSummary
                            aria-controls="panel1d-content"
                            className={"!mx-3 !p-0 text-blue-800 flex items-center " + (!!menu.subMenus.filter(x => location.pathname.includes(x.link)).length ? "hover:text-blue-800" : "hover:text-blue-500")}
                        >
                            <i className={menu.iconNameWithClass + " ut--xl mr-3"}/>
                            <p className="font-medium">{menu.text}</p>
                        </AccordionSummary>
                        <AccordionDetails className="bg-blue-50 pl-6">
                            <ul className="ml-5 text-gray-600">
                                {menu.subMenus.map((subMenu, i) => (
                                    <NavLink key={i} to={subMenu.link} className="">
                                        <li
                                            className={"py-[4px] hover:text-blue-500 " + (location.pathname.includes(subMenu.link) ? "text-blue-500" : "")}>
                                            {subMenu.text}
                                        </li>
                                    </NavLink>
                                ))}
                            </ul>
                        </AccordionDetails>
                    </Accordion>
                    :
                    <Accordion key={i}
                               expanded={false}
                               className={"mx-5 my-[10px] rounded cursor-pointer"}
                               onChange={() => handleCollapse(i)}>
                        <AccordionSummary
                            aria-controls="panel1d-content"
                            className={"!mx-3 !p-0 text-blue-800 flex items-center hover:text-blue-500"}
                        >
                            <ul>
                                <NavLink key={i} to={menu.link} className="flex">
                                    <li
                                        className={"py-[4px] hover:text-blue-500 " + (location.pathname.includes(menu.link) ? "text-blue-500" : "")}>
                                        <i className={menu.iconNameWithClass + " ut--xl mr-3"}/> {menu.text}
                                    </li>
                                </NavLink>
                            </ul>
                        </AccordionSummary>
                    </Accordion>
            })}
        </div>
    );
};

export default MenuCompany;
